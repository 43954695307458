import LoadingSpinner from '@frontend/components/LoadingSpinner';
import { UserRoles } from '@frontend/constants/user-roles';
import ApolloProviderWrapper from '@frontend/providers/apollo';
import { AuthProvider } from '@frontend/providers/auth';
import { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AuthRoute from './AuthRoute';

const Users = lazy(() => import('@frontend/pages/Admin/Users'));
const About = lazy(() => import('@frontend/pages/About'));
const NotFound = lazy(() => import('@frontend/pages/NotFound404'));
const WeatherEducational = lazy(
  () => import('@frontend/pages/Weather/Educational'),
);
const WeatherPoints = lazy(() => import('@frontend/pages/Weather/Points'));
const InsertWeatherData = lazy(
  () => import('@frontend/pages/Weather/InsertWeatherData'),
);
const WeatherParameters = lazy(
  () => import('@frontend/pages/Weather/Parameters'),
);
const WaterPoints = lazy(() => import('@frontend/pages/WaterQuality/Points'));
const WaterEducational = lazy(
  () => import('@frontend/pages/WaterQuality/Educational'),
);
const WaterParameters = lazy(
  () => import('@frontend/pages/WaterQuality/Parameters'),
);
const MeasurementUnits = lazy(
  () => import('@frontend/pages/WaterQuality/MeasurementUnits'),
);
const Laboratories = lazy(
  () => import('@frontend/pages/WaterQuality/Laboratories'),
);
const AnalysisMethods = lazy(
  () => import('@frontend/pages/WaterQuality/AnalysisMethods'),
);
const CollectionMethods = lazy(
  () => import('@frontend/pages/WaterQuality/CollectionMethods'),
);
const WeatherCondition = lazy(
  () => import('@frontend/pages/WaterQuality/WeatherCondition'),
);
const WaterPointInfo = lazy(
  () => import('@frontend/pages/WaterQuality/PointInfo'),
);
const FaunaPoints = lazy(() => import('@frontend/pages/Fauna/Points'));
const InsertFaunaData = lazy(
  () => import('@frontend/pages/Fauna/InsertFaunaData'),
);
const FaunaEducational = lazy(
  () => import('@frontend/pages/Fauna/Educational'),
);
const FaunaSpecies = lazy(() => import('@frontend/pages/Fauna/Species'));
const FaunaGenus = lazy(() => import('@frontend/pages/Fauna/Genus'));
const FaunaFamily = lazy(() => import('@frontend/pages/Fauna/Family'));
const FaunaOrder = lazy(() => import('@frontend/pages/Fauna/Order'));
const FaunaClass = lazy(() => import('@frontend/pages/Fauna/Class'));
const FaunaPhylum = lazy(() => import('@frontend/pages/Fauna/Phylum'));
const FaunaNames = lazy(() => import('@frontend/pages/Fauna/Names'));
const FaunaCollectionMethods = lazy(
  () => import('@frontend/pages/Fauna/CollectionMethods'),
);
const FaunaPointInfo = lazy(() => import('@frontend/pages/Fauna/PointInfo'));
const WeatherPointInfo = lazy(
  () => import('@frontend/pages/Weather/PointInfo'),
);
const ForgotPassword = lazy(() => import('@frontend/pages/ForgotPassword'));
const Profile = lazy(() => import('@frontend/pages/Profile/'));
const InsertWaterData = lazy(
  () => import('@frontend/pages/WaterQuality/InsertWaterData'),
);
const Companies = lazy(() => import('@frontend/pages/Admin/Companies'));
const DataManagement = lazy(
  () => import('@frontend/pages/Admin/DataManagement'),
);
const Documents = lazy(() => import('@frontend/pages/Admin/Documents'));
const Meetings = lazy(() => import('@frontend/pages/Admin/Meetings'));
const FilesUpload = lazy(() => import('@frontend/pages/FilesUpload'));
const Publications = lazy(() => import('@frontend/pages/Publications'));
const Subwatersheds = lazy(() => import('@frontend/pages/Subwatersheds'));
const WaterResources = lazy(() => import('@frontend/pages/WaterResources'));
const Cities = lazy(() => import('@frontend/pages/Cities'));
const Enterprises = lazy(() => import('@frontend/pages/Enterprises'));
const FileInfo = lazy(() => import('@frontend/pages/FileInfo'));
const Login = lazy(() => import('@frontend/pages/Login'));
const Main = lazy(() => import('@frontend/pages/Main'));
const Signup = lazy(() => import('@frontend/pages/Signup'));
const Hydroelectrics = lazy(() => import('@frontend/pages/Hydroelectrics'));
const Dashboard = lazy(() => import('@frontend/pages/Dashboard'));
const ResetPassword = lazy(() => import('@frontend/pages/ResetPassword'));
const EnterpriseInfo = lazy(() => import('@frontend/pages/EnterpriseInfo'));
const CityInfo = lazy(() => import('@frontend/pages/Cities/CityInfo'));
const SubwatershedInfo = lazy(
  () => import('@frontend/pages/Subwatersheds/SubwatershedInfo'),
);

const publicRoutes = [
  { path: '/', element: <Main /> },
  { path: '/home', element: <Main /> },
  { path: '/login', element: <Login /> },
  { path: '/signup', element: <Signup /> },
  { path: '/forgotPassword', element: <ForgotPassword /> },
  { path: '/resetPassword/:token', element: <ResetPassword /> },
  { path: '/about', element: <About /> },
  { path: '/hydroelectrics', element: <Hydroelectrics /> },
  { path: '/notFound', element: <NotFound /> },
];

const protectedRoutes = [
  {
    path: '/dashboard',
    element: <Dashboard />,
    allowedRoles: [
      UserRoles.User,
      UserRoles.Company,
      UserRoles.Technician,
      UserRoles.Admin,
    ],
  },
  {
    path: '/admin/users',
    element: <Users />,
    allowedRoles: [UserRoles.Admin],
  },
  {
    path: '/enterprises',
    element: <Enterprises />,
    allowedRoles: [
      UserRoles.User,
      UserRoles.Company,
      UserRoles.Technician,
      UserRoles.Admin,
    ],
  },
  {
    path: '/enterprise/:id',
    element: <EnterpriseInfo />,
    allowedRoles: [
      UserRoles.User,
      UserRoles.Company,
      UserRoles.Technician,
      UserRoles.Admin,
    ],
  },
  {
    path: '/admin/companies',
    element: <Companies />,
    allowedRoles: [UserRoles.Admin],
  },
  {
    path: '/admin/dataManagement',
    element: <DataManagement />,
    allowedRoles: [UserRoles.Admin],
  },
  {
    path: '/admin/documents',
    element: <Documents />,
    allowedRoles: [UserRoles.Admin],
  },
  {
    path: '/admin/meetings',
    element: <Meetings />,
    allowedRoles: [UserRoles.Admin],
  },
  {
    path: '/city',
    element: <Cities />,
    allowedRoles: [
      UserRoles.User,
      UserRoles.Company,
      UserRoles.Technician,
      UserRoles.Admin,
    ],
  },
  {
    path: '/cityInfo/:id',
    element: <CityInfo />,
    allowedRoles: [
      UserRoles.User,
      UserRoles.Company,
      UserRoles.Technician,
      UserRoles.Admin,
    ],
  },
  {
    path: '/filesUpload',
    element: <FilesUpload />,
    allowedRoles: [
      UserRoles.User,
      UserRoles.Company,
      UserRoles.Technician,
      UserRoles.Admin,
    ],
  },
  {
    path: '/fileInfo/:id',
    element: <FileInfo />,
    allowedRoles: [
      UserRoles.User,
      UserRoles.Company,
      UserRoles.Technician,
      UserRoles.Admin,
    ],
  },
  {
    path: '/publications',
    element: <Publications />,
    allowedRoles: [
      UserRoles.User,
      UserRoles.Company,
      UserRoles.Technician,
      UserRoles.Admin,
    ],
  },
  {
    path: '/subwatershed',
    element: <Subwatersheds />,
    allowedRoles: [
      UserRoles.User,
      UserRoles.Company,
      UserRoles.Technician,
      UserRoles.Admin,
    ],
  },
  {
    path: '/subwatershedInfo/:id',
    element: <SubwatershedInfo />,
    allowedRoles: [
      UserRoles.User,
      UserRoles.Company,
      UserRoles.Technician,
      UserRoles.Admin,
    ],
  },
  {
    path: '/waterResources',
    element: <WaterResources />,
    allowedRoles: [
      UserRoles.User,
      UserRoles.Company,
      UserRoles.Technician,
      UserRoles.Admin,
    ],
  },
  {
    path: '/profile',
    element: <Profile />,
    allowedRoles: [
      UserRoles.User,
      UserRoles.Company,
      UserRoles.Technician,
      UserRoles.Admin,
    ],
  },
  // Weather
  {
    path: '/weather',
    element: <WeatherEducational />,
    allowedRoles: [
      UserRoles.User,
      UserRoles.Company,
      UserRoles.Technician,
      UserRoles.Admin,
    ],
  },
  {
    path: '/weather/educational',
    element: <WeatherEducational />,
    allowedRoles: [
      UserRoles.User,
      UserRoles.Company,
      UserRoles.Technician,
      UserRoles.Admin,
    ],
  },
  {
    path: '/weather/points',
    element: <WeatherPoints />,
    allowedRoles: [
      UserRoles.User,
      UserRoles.Company,
      UserRoles.Technician,
      UserRoles.Admin,
    ],
  },
  {
    path: '/weather/pointInfo',
    element: <WeatherPointInfo />,
    allowedRoles: [
      UserRoles.User,
      UserRoles.Company,
      UserRoles.Technician,
      UserRoles.Admin,
    ],
  },
  {
    path: '/weather/insertData',
    element: <InsertWeatherData />,
    allowedRoles: [
      UserRoles.User,
      UserRoles.Company,
      UserRoles.Technician,
      UserRoles.Admin,
    ],
  },
  {
    path: '/weather/parameters',
    element: <WeatherParameters />,
    allowedRoles: [
      UserRoles.User,
      UserRoles.Company,
      UserRoles.Technician,
      UserRoles.Admin,
    ],
  },
  // Water Quality
  {
    path: '/waterQuality',
    element: <WaterEducational />,
    allowedRoles: [
      UserRoles.User,
      UserRoles.Company,
      UserRoles.Technician,
      UserRoles.Admin,
    ],
  },
  {
    path: '/waterQuality/educational',
    element: <WaterEducational />,
    allowedRoles: [
      UserRoles.User,
      UserRoles.Company,
      UserRoles.Technician,
      UserRoles.Admin,
    ],
  },
  {
    path: '/waterQuality/points',
    element: <WaterPoints />,
    allowedRoles: [
      UserRoles.User,
      UserRoles.Company,
      UserRoles.Technician,
      UserRoles.Admin,
    ],
  },
  {
    path: '/waterQuality/pointInfo/:pointId',
    element: <WaterPointInfo />,
    allowedRoles: [
      UserRoles.User,
      UserRoles.Company,
      UserRoles.Technician,
      UserRoles.Admin,
    ],
  },
  {
    path: '/waterQuality/weatherCondition',
    element: <WeatherCondition />,
    allowedRoles: [
      UserRoles.User,
      UserRoles.Company,
      UserRoles.Technician,
      UserRoles.Admin,
    ],
  },
  {
    path: '/waterQuality/collectionMethods',
    element: <CollectionMethods />,
    allowedRoles: [
      UserRoles.User,
      UserRoles.Company,
      UserRoles.Technician,
      UserRoles.Admin,
    ],
  },
  {
    path: '/waterQuality/laboratories',
    element: <Laboratories />,
    allowedRoles: [
      UserRoles.User,
      UserRoles.Company,
      UserRoles.Technician,
      UserRoles.Admin,
    ],
  },
  {
    path: '/waterQuality/analysisMethods',
    element: <AnalysisMethods />,
    allowedRoles: [
      UserRoles.User,
      UserRoles.Company,
      UserRoles.Technician,
      UserRoles.Admin,
    ],
  },
  {
    path: '/waterQuality/measurementUnits',
    element: <MeasurementUnits />,
    allowedRoles: [
      UserRoles.User,
      UserRoles.Company,
      UserRoles.Technician,
      UserRoles.Admin,
    ],
  },
  {
    path: '/waterQuality/insertData',
    element: <InsertWaterData />,
    allowedRoles: [
      UserRoles.User,
      UserRoles.Company,
      UserRoles.Technician,
      UserRoles.Admin,
    ],
  },
  {
    path: '/waterQuality/parameters',
    element: <WaterParameters />,
    allowedRoles: [
      UserRoles.User,
      UserRoles.Company,
      UserRoles.Technician,
      UserRoles.Admin,
    ],
  },
  // Fauna
  {
    path: '/fauna/points',
    element: <FaunaPoints />,
    allowedRoles: [
      UserRoles.User,
      UserRoles.Company,
      UserRoles.Technician,
      UserRoles.Admin,
    ],
  },
  {
    path: '/fauna/pointInfo/:pointId',
    element: <FaunaPointInfo />,
    allowedRoles: [
      UserRoles.User,
      UserRoles.Company,
      UserRoles.Technician,
      UserRoles.Admin,
    ],
  },
  {
    path: '/fauna/insertData',
    element: <InsertFaunaData />,
    allowedRoles: [
      UserRoles.User,
      UserRoles.Company,
      UserRoles.Technician,
      UserRoles.Admin,
    ],
  },
  {
    path: '/fauna',
    element: <FaunaEducational />,
    allowedRoles: [
      UserRoles.User,
      UserRoles.Company,
      UserRoles.Technician,
      UserRoles.Admin,
    ],
  },
  {
    path: '/fauna/educational',
    element: <FaunaEducational />,
    allowedRoles: [
      UserRoles.User,
      UserRoles.Company,
      UserRoles.Technician,
      UserRoles.Admin,
    ],
  },
  {
    path: '/fauna/species',
    element: <FaunaSpecies />,
    allowedRoles: [
      UserRoles.User,
      UserRoles.Company,
      UserRoles.Technician,
      UserRoles.Admin,
    ],
  },
  {
    path: '/fauna/genus',
    element: <FaunaGenus />,
    allowedRoles: [
      UserRoles.User,
      UserRoles.Company,
      UserRoles.Technician,
      UserRoles.Admin,
    ],
  },
  {
    path: '/fauna/family',
    element: <FaunaFamily />,
    allowedRoles: [
      UserRoles.User,
      UserRoles.Company,
      UserRoles.Technician,
      UserRoles.Admin,
    ],
  },
  {
    path: '/fauna/order',
    element: <FaunaOrder />,
    allowedRoles: [
      UserRoles.User,
      UserRoles.Company,
      UserRoles.Technician,
      UserRoles.Admin,
    ],
  },
  {
    path: '/fauna/class',
    element: <FaunaClass />,
    allowedRoles: [
      UserRoles.User,
      UserRoles.Company,
      UserRoles.Technician,
      UserRoles.Admin,
    ],
  },
  {
    path: '/fauna/phylum',
    element: <FaunaPhylum />,
    allowedRoles: [
      UserRoles.User,
      UserRoles.Company,
      UserRoles.Technician,
      UserRoles.Admin,
    ],
  },
  {
    path: '/fauna/names',
    element: <FaunaNames />,
    allowedRoles: [
      UserRoles.User,
      UserRoles.Company,
      UserRoles.Technician,
      UserRoles.Admin,
    ],
  },
  {
    path: '/fauna/collectionMethods',
    element: <FaunaCollectionMethods />,
    allowedRoles: [
      UserRoles.User,
      UserRoles.Company,
      UserRoles.Technician,
      UserRoles.Admin,
    ],
  },
];

const ProtectedRoute = ({ element, allowedRoles }: any) => (
  <AuthRoute allowedRoles={allowedRoles}>{element}</AuthRoute>
);

export default function Router() {
  return (
    <ApolloProviderWrapper>
      <BrowserRouter>
        <AuthProvider>
          <Suspense fallback={<LoadingSpinner />}>
            <Routes>
              {/* Rotas Públicas */}
              {publicRoutes.map(({ path, element }) => (
                <Route key={path} path={path} element={element} />
              ))}

              {/* Rotas Protegidas */}
              {protectedRoutes.map(({ path, element, allowedRoles }) => (
                <Route
                  key={path}
                  path={path}
                  element={
                    <ProtectedRoute
                      element={element}
                      allowedRoles={allowedRoles}
                    />
                  }
                />
              ))}
            </Routes>
          </Suspense>
        </AuthProvider>
      </BrowserRouter>
    </ApolloProviderWrapper>
  );
}
