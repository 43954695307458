import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import LoadingSpinner from '@frontend/components/LoadingSpinner';
import { UserRoles } from '@frontend/constants/user-roles';

const CHECK_AUTHORIZATION = gql`
  query CheckAuthorization($requiredRoles: [String!]!) {
    checkAuthorization(requiredRoles: $requiredRoles) {
      isAuthorized
    }
  }
`;

interface AuthRouteProps {
  children: React.ReactNode;
  allowedRoles?: UserRoles[];
}

const AuthRoute = ({ allowedRoles, children }: AuthRouteProps) => {
  const location = useLocation();
  const [rolesLoaded, setRolesLoaded] = useState(false);
  const [roles, setRoles] = useState<string[]>([]);

  const token = sessionStorage.getItem('token');

  useEffect(() => {
    if (allowedRoles && allowedRoles.length > 0) {
      setRoles(allowedRoles);
      setRolesLoaded(true);
    }
  }, [allowedRoles]);

  const { loading, error, data } = useQuery(CHECK_AUTHORIZATION, {
    variables: { requiredRoles: roles },
    context: {
      headers: {
        Authorization: token ? `Bearer ${token}` : '',
      },
    },
    skip: !rolesLoaded,
  });

  if (!rolesLoaded || loading) {
    return <LoadingSpinner />;
  }

  if (error || !data.checkAuthorization.isAuthorized) {
    return <Navigate to="/notFound" state={{ from: location }} replace />;
  }

  return children ? children : <Outlet />;
};

export default AuthRoute;
